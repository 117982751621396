import Head from 'next/head';
import SiteLayout from '../../Layout/SiteLayout';
import SupportPage from '../../components/Support/pages/SupportPage';
import { getUserInfoSSR } from '../../utils/cf';

const Support = () => {
  return (
    <>
      <Head>
        <title>Support - Driffle</title>
        <meta name="description" content="" />
        <link rel="canonical" href={`https://driffle.com/support`} />
        <meta httpEquiv="last-modified" content={new Date().toDateString()} />
      </Head>
      <SiteLayout>
        <SupportPage />
      </SiteLayout>
    </>
  );
};

export default Support;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
