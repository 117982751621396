import { useRouter } from 'next/router';
import React from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../../contexts/LocalizationContext';
import {
  Container,
  ContentDescription,
  ContentTitle,
  ContentWrapper,
  IssueOption,
  IssueOptionsContainer,
  MainSection,
  MainWrapper,
  Subtitle,
  TitleContainer,
  TitleWrapper,
} from '../SupportStyles';
import useSupportFaq from '../hooks/useSupportFaq';

const SupportPage: React.FC = () => {
  const router = useRouter();
  const { issueTypes } = useSupportFaq();
  const theme = useTheme();

  const { messages } = useLocale();
  const {
    choose_the_type_of_issue_msg,
    what_kind_of_issue_are_you_experience_msg,
  } = messages || {};

  return (
    <MainSection>
      <MainWrapper>
        <Container>
          <TitleWrapper>
            <TitleContainer>
              <p>{choose_the_type_of_issue_msg}</p>
            </TitleContainer>
            <Subtitle>{what_kind_of_issue_are_you_experience_msg}</Subtitle>
          </TitleWrapper>
          <IssueOptionsContainer>
            {issueTypes?.map((item, index) => {
              return (
                <IssueOption key={index} onClick={() => router.push(item.to)}>
                  <div style={{ width: '80px', height: '80px' }}>
                    <img
                      src={
                        theme.mode === 'dark'
                          ? item.img + '-dark.svg'
                          : item.img + '-light.svg'
                      }
                      width={'80px'}
                      height={'80px'}
                      alt={item.title}
                    />
                  </div>
                  <ContentWrapper>
                    <ContentTitle>{item.title}</ContentTitle>
                    <ContentDescription>{item.description}</ContentDescription>
                  </ContentWrapper>
                </IssueOption>
              );
            })}
          </IssueOptionsContainer>
        </Container>
      </MainWrapper>
    </MainSection>
  );
};

export default SupportPage;
